<template>
  <div>
    <CalendarView
      :show-date="showDate"
      @click-date="selectDate"
      @click-item="selectEvent"
      class="theme-default schedule isDayNumberHide"
      :class="{'isRentable': showRentable}"
      :date-classes="dateClasses"
      :items="events"
      itemTop="50px"
      itemContentHeight="50px"
    >
      <!-- 自訂 Header -->
      <template #header="{ headerProps }">
        <div class="schedule-header">
          <button
            type="button"
            class="schedule-header-prev"
            @click="setShowDate(headerProps.previousPeriod)"
          >
            上個月
          </button>

          <div>
            <Popper
              class="tooltip"
              @open:popper="togglePopper(true)"
              @close:popper="togglePopper(false)"
              arrow
            >
              <div class="schedule-header-title">
                {{ headerProps.periodEnd.getFullYear() }} 年
                {{ headerProps.periodEnd.getMonth() + 1 }} 月
                <i class="icon icon-arrow-down" aria-hidden="true"></i>
              </div>
              <template #content>
                <div v-if="popperYM !== null" class="schedule-popper">
                  <div class="popper-header">
                    <button @click="popperYM.year -= 1">
                      <i class="icon icon-arrow-left" aria-hidden="true"></i>
                    </button>
                    <div>{{ popperYM.year }}</div>
                    <button @click="popperYM.year += 1">
                      <i class="icon icon-arrow-right" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="popper-body">
                    <div class="popper-body-cell" v-for="num in 12" :key="num">
                      <button @click="selectPopper(num)">{{ num }}月</button>
                    </div>
                    <button @click="setShowDate(initDate)">返回今天</button>
                  </div>
                </div>
              </template>
            </Popper>
          </div>
          <button
            type="button"
            class="schedule-header-next"
            @click="setShowDate(headerProps.nextPeriod)"
          >
            下個月
          </button>
        </div>
      </template>

      <!-- 自訂 dayContent -->
      <template #dayContent="{ day }">
        <div class="custom-day-number">
          <span class="date">{{ day.getDate() }}</span>
          <!-- 標籤 -->
          <span class="tag" :class="labelClassCreator(getDateInfo(day)?.type)">
            {{ getDateInfo(day)?.title }}
          </span>
          <!-- 手機版點點 -->
          <span class="dot" :class="`dot-type-${dot}`" v-for="dot in getDateInfo(day)?.type" :key="dot"></span>
        </div>
      </template>
    </CalendarView>
    <div class="schedule-footer">
      <div>
        <slot name="footer"></slot>
      </div>
      <button class="btn btn-light" @click="setShowDate(initDate)">返回今天</button>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { CalendarView, CalendarMath } from 'vue-simple-calendar';

const labelTypeTable = {
  1: 'tag-blue',
  2: 'tag-orange',
  3: 'tag-gray',
};

export default {
  props: {
    initDate: {
      type: Object,
      default: () => this.todayDateObj(),
    },
    scheduleLabel: {
      type: Array,
      default: () => [],
    },
    scheduleEvents: {
      type: Array,
      default: () => [],
    },
    scheduleDots: {
      type: Array,
      default: () => [],
    },
    showEventType: {
      type: Boolean,
      default: false,
    },
    showRentable: {
      type: Boolean,
      default: true,
    },
    selectedDate: Object,
  },
  data() {
    return {
      showDate: null,
      popperYM: null,
      events: [],
      hoverEventId: null,
    };
  },
  components: {
    CalendarView,
  },
  methods: {
    selectDate(dateObj) {
      this.$emit('selectDate', dateObj);
    },
    selectEvent(eventObj, windowEvent) {
      this.$emit('selectEvent', eventObj, windowEvent);
    },
    setShowDate(dateObj) {
      this.showDate = dateObj;
    },
    togglePopper(isOpen) {
      if (isOpen) {
        this.popperYM = {
          year: this.showDate.getFullYear(),
        };
      } else {
        this.popperYM = null;
      }
    },
    selectPopper(month) {
      const { year } = this.popperYM;
      const selectDate = new Date(year, month - 1);
      this.setShowDate(selectDate);
    },
    getDateInfo(dateObj) {
      return _.find(this.scheduleDots, (event) => this.$moment(event.startDate).isSame(dateObj, 'day'));
    },
    labelClassCreator(types) {
      return _.map(types, (type) => labelTypeTable[type]);
    },
    eventClassCreator(eventObj) {
      if (this.showEventType) {
        const classes = [];
        if (eventObj.notification.length > 0) classes.push('has-bell');
        if (eventObj.siteId === 1) classes.push('tag-blue');
        if (eventObj.siteId === 2) classes.push('tag-orange');
        return classes;
      }

      return ['tag-gray'];
    },
  },
  computed: {
    // 日期格的 className
    dateClasses() {
      // 含標籤
      const datesHasLabel = _.map(this.scheduleLabel, (dateObj) => dateObj.startDate);
      const hasLabel = _.reduce(
        datesHasLabel,
        (result, date) => {
          result[CalendarMath.isoYearMonthDay(new Date(date))] = ['hasLabel']; /* eslint-disable-line */
          return result;
        },
        {},
      );

      // selected date
      const selected = { [CalendarMath.isoYearMonthDay(this.selectedDate)]: ['selected'] };

      return _.mergeWith(hasLabel, selected, (other, source) => {
        if (_.isArray(other)) {
          return other.concat(source);
        }
        return source;
      });
    },
  },
  watch: {
    scheduleEvents: {
      immediate: true,
      deep: true,
      handler(newEvents) {
        this.events = _.map(newEvents, (event) => ({
          classes: this.eventClassCreator(event),
          ...event,
        }));
      },
    },
    showDate(showDate) {
      this.$emit('changeCalendar', showDate);
    },
  },
  created() {
    this.showDate = this.initDate;
  },
};
</script>
