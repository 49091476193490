<template>
  <div class="collapse" :class="{'is-opened': isActive}">
    <div class="collapse-title" @click="toggle">
      <slot name="title"></slot>
    </div>
    <div ref="content" class="_collapse" :style="[isActive ? { height: computedHeight } : {}]">
      <div class="collapse-content">
        <slot name="content" :initHeight="initHeight"></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
  ._collapse {
    height: 0;
    overflow: hidden;
    transition: ease-in-out .3s;
  }
</style>
<script>
export default {
  data() {
    return {
      isActive: false,
      computedHeight: 0,
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    initHeight() {
      const $el = this.$refs.content;
      $el.style.height = 'auto';
      $el.style.position = 'absolute';
      $el.style.visibility = 'hidden';
      $el.style.display = 'block';

      const { height } = window.getComputedStyle($el);
      this.computedHeight = height;

      $el.style.position = null;
      $el.style.visibility = null;
      $el.style.display = null;
      $el.style.height = 0;
    },
  },
  mounted() {
    this.initHeight();
  },
};
</script>
